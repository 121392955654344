export const VALID_GUESSES = [
    "pasta",
    "goraz",
    "pesto",
    "blini",
    "ervas",
    "brule",
    "pipis",
    "migas",
    "milho",
    "ginja",
    "clara",
    "sargo",
    "pizza",
    "sufle",
    "folar",
    "bakso",
    "vinho",
    "creme",
    "truta",
    "tosta",
    "ancho",
    "barbo",
    "savel",
    "pepsi",
    "prego",
    "kebab",
    "cabra",
    "galao",
    "ossos",
    "iscas",
    "crepe",
    "sidra",
    "pingo",
    "rosti",
    "carpa",
    "favas",
    "piano",
    "wurst",
    "polvo",
    "peixe",
    "pudim",
    "tinto",
    "suino",
    "buzio",
    "dende",
    "couve",
    "louro",
    "stout",
    "saque",
    "trufa",
    "molho",
    "porco",
    "anona",
    "arroz",
    "chaja",
    "porto",
    "penne",
    "moela",
    "pingu",
    "natas",
    "menta",
    "sushi",
    "bagel",
    "mamao",
    "shoyu",
    "endro",
    "panko",
    "manga",
    "cravo",
    "fruto",
    "caril",
    "cacau",
    "quibe",
    "tacos",
    "salsa",
    "babka",
    "pluma",
    "sonho",
    "gouda",
    "papas",
    "tapas",
    "lulas",
    "verde",
    "melao",
    "leite",
    "gamba",
    "trigo",
    "torta",
    "vodka",
    "choco",
    "calda",
    "bucho",
    "aroma",
    "humus",
    "bronx",
    "xerez",
    "miolo",
    "caqui",
    "ostra",
    "picle",
    "meloa",
    "rojao",
    "chila",
    "solha",
    "sames",
    "ruote",
    "lucio",
    "aneto",
    "chili",
    "vagem",
    "caldo",
    "manta",
    "cidra",
    "limao",
    "banha",
    "nacho",
    "avela",
    "licor",
    "massa",
    "polpa",
    "sarda",
    "tarte",
    "pinga",
    "bacon",
    "amora",
    "tango",
    "perca",
    "xarem",
    "lombo",
    "caçao",
    "fruta",
    "tawny",
    "locro",
    "pargo",
    "aveia",
    "donut",
    "atole",
    "fanta",
    "matza",
    "carne",
    "misto",
]
